<template>
<div>
	<h3 style="line-height:40px;font-size:14px">售后订单</h3>
	<div class="order-table-wrapper">
		<el-form-item>
			<el-table
					border
					stripe
					size="mini"
					element-loading-text="拼命加载中"
					:row-style="{ height: '48px' }"
					:cell-style="{ padding: '0px' }"
					:header-cell-style="{ background: '#e5f2ff' }"
					style="width: 100%; margin-bottom: 1px;"
					header-cell-class-name="normal-table-header"
					:data="orderTable"
			>
				<template v-for="item of orderColumns">
					<el-table-column :show-overflow-tooltip="true" v-if="item.prop === 'payType'" :key="item.prop" :label="item.label" width="120px">
						<template v-slot:default="{ row }">
							{{ payTypeMap[row.payType] }}
						</template>
					</el-table-column>
					<el-table-column :show-overflow-tooltip="true" v-else-if="item.prop === 'allPay'" :key="item.prop" :label="item.label">
						<template v-slot:default="{ row }">
							{{ allPayMap[row.allPay] }}
						</template>
					</el-table-column>
					<el-table-column :show-overflow-tooltip="true" v-else-if="item.prop === 'seSeQuenName'" :key="item.prop" :label="item.label" width="180px" :fixed="item.fixed">
						<template v-slot:default="{ row }">
							<span>{{ row.seSeQuenName }}</span>
							<span style="color: #337AFF; margin-top: 6px">{{ row.commodityName }}</span>
						</template>
					</el-table-column>
					<el-table-column :show-overflow-tooltip="true" v-else-if="item.prop === 'orderStatus'" :key="item.prop" :label="item.label" width="120px">--</el-table-column>
					<el-table-column :show-overflow-tooltip="true" v-else-if="item.prop === 'afterSaleTypes'" :key="item.prop" :label="item.label">--</el-table-column>
					<el-table-column :show-overflow-tooltip="true" v-else-if="item.prop === 'registDate'" :key="item.prop" :label="item.label" width="180px">
						<template v-slot:default="{ row }">
							{{ row.registDate | formatDate("YYYY-MM-DD HH:mm:ss") }}
						</template>
					</el-table-column>
					<el-table-column :show-overflow-tooltip="true" v-else-if="item.prop === 'installmentType'" :key="item.prop" :prop="item.prop" :label="item.label" :width="item.width">
						<template v-slot:default="{ row }">
							<span v-if="row.installmentType != 0">{{ installmentTypeMap[row.installmentType] }}</span>
							<span v-else>- -</span>
						</template>
					</el-table-column>
					<el-table-column :show-overflow-tooltip="true" v-else-if="item.prop === 'tradeSerialNumber'" :key="item.prop" :prop="item.prop" :label="item.label" :width="item.width">
							<template v-slot:default="{ row }">
								<span v-if="row.installmentType != 0">{{ row.tradeSerialNumber }}</span>
								<span v-else>- -</span>
						</template>
					</el-table-column>
					<el-table-column :show-overflow-tooltip="true"
						v-else-if="item.prop === 'customerIdCode'"
						:key="item.prop"
						:prop="item.prop"
						:label="item.label"
						:width="item.width"
						:fixed="item.fixed"
					>
						<template v-slot:default="{row}">
							<span >{{ row[item.prop] | empty('--')}}</span>
						</template>
					</el-table-column>
					<el-table-column :show-overflow-tooltip="true" v-else :key="item.prop" :prop="item.prop" :label="item.label" :width="item.width" :fixed="item.fixed">
						<template v-slot:default="{row}">
							<span v-if="['prodLineName', 'goodsTypeName', 'priTypeName', 'campusName'].includes(item.prop)">{{ row[item.prop] | empty('--') }}</span>
							<span v-else-if="['goodsSpecName', 'goodsCategoryName'].includes(item.prop)">--</span>
							<span v-else>{{ row[item.prop] }}</span>
						</template>
					</el-table-column>
				</template>
			</el-table>
		</el-form-item>
	</div>
</div>
</template>

<script>
import { allPayMap, installmentTypeMap, orderStatusMap, payTypeMap } from '@/views/order/constants'
export default {
	props: {
		orderTable: Array,
	},
	data(){
		return {
      orderColumns: [
        { label: "订单号", prop: "orderNo", width: "200px", fixed: true },
        { label: "报读商品", prop: "seSeQuenName", fixed: true },
        { label: "班型", prop: "goodsSpecName", width: "120px" },
        { label: "产品线", prop: "prodLineName",width:"120px" },
        { label: "商品分类", prop: "goodsCategoryName",width: "100px" },
        { label: "产品系列", prop: "priTypeName",width: "100px" },
        { label: "学习帐号", prop: "phone" ,width: "140px"},
        { label: "学员姓名", prop: "stuName",width: "140px" },
        { label: "身份证号", prop: "idCard", width: "185px" },
        { label: "客户编号", prop: "customerIdCode", width: "185px" },
        { label: "应收金额", prop: "dnshoulddCost",width: "100px" },
        { label: "已收金额", prop: "thisReceivAble",width: "100px" },
        { label: "订单状态", prop: "orderStatus",width: "130px" },
        { label: "售后", prop: "afterSaleTypes",width: "100px" },
        { label: "招生老师", prop: "recruiterName" ,width: "140px"},
        { label: "上课校区", prop: "campusName",width: "140px" },
        { label: "报名时间", prop: "registDate",width: "140px" },
      ],
			payTypeMap,
			allPayMap,
			orderStatusMap,
			installmentTypeMap,
		}
	}
}
</script>

<style>
</style>